import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Markdown from "../components/markdown"
import PageTitleSmallBMargin from "../components/page-title-small-b-margin"
import ShareButtons from "../components/share-buttons"

const Template = ({ data, location }) => {
  const { node } = data
  const title = node.title
  return (
    <Layout
      title={title}
      description={node.meta_description}
      keywords={node.meta_keywords}
      image={node.ogp_image?.publicURL}
      breadcrumbs={buildBreadcrumbs(node)}
    >
      <div className="pc:flex pc:flex-row pc:justify-between pc:items-center pc:mb-6">
        <PageTitleSmallBMargin>{title}</PageTitleSmallBMargin>
        <div class="tablet_sp:flex tablet_sp:flex-row tablet_sp:justify-end tablet_sp:mb-3">
          <ShareButtons url={location.href} title={title} size={36} />
        </div>
      </div>
      <section className="pc:mb-16 tablet_sp:mb-8">
        <Markdown>{node.body ?? ``}</Markdown>
      </section>
    </Layout>
  )
}

/**
 * パンくずリストを生成する
 */
const buildBreadcrumbs = (product) => {
  if (product?.product_category_main) {
    const category = product.product_category_main

    if (category?.path && category?.name) {
      return [
        { label: category.name ?? ``, href: category.path },
        { label: product.title, href: null },
      ]
    }
  }

  // Strapi の制限で `post_area_main` は必須にできないので
  // `post_area_main` が空の場合はフォールバックする
  return [
    { label: product.title, href: null },
  ]
}

export default Template

export const pageQuery = graphql`
  query($path: String!) {
    node: tbProduct(path: { eq: $path }) {
      title
      ogp_image {
        publicURL
      }
      meta_description
      meta_keywords
      file {
        publicURL
      }
      product_category_main {
        name
        path
      }
      body
    }
  }
`
