/**
 * 下マージンの小さなページタイトルの h1 の派生
 *
 * SNS のシェアボタンと 2 カラムレイアウトにするときにだけ使用します
 */

import * as React from "react"
import PropTypes from "prop-types"

const PageTitleSmallBMargin = ({ children }) => {
  return (
    <h1 className="pc:mb-2 pc:text-2xl tablet_sp:text-base tablet_sp:mb-2">{children}</h1>
  )
}

PageTitleSmallBMargin.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
}

export default PageTitleSmallBMargin
